/*
 * Content:
 *
 * Page
 * Page loading
 * Page wallpaper
 * Page investors
 */


// Page
// ============================================================================

.page {
  position: relative;
  padding-top: $navbar-min-height;
}

.page-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.page-content {
  padding-top: spacer(6);
  padding-bottom: rem(64px);
}


// Page loading
// ============================================================================

body {
  .page-loading-icon {
    position: relative;
    left: 10px;
    display: none;
  }

  &.wait {
    cursor: wait !important; // stylelint-disable-line declaration-no-important

    .page-loading-icon {
      display: inline-block;
    }
  }
}


// Page wallpaper
// ============================================================================

.page-wallpaper {
  position: relative;
  display: grid;
  min-height: 100vh;
  padding: rem(56px) 0;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: none;
    width: calc(50vw - #{$grid-gutter-width} * .5);
    height: 100%;
    content: "";
    background:
      linear-gradient(to bottom, rgba($primary, .1) 0%, $primary 100%),
      var(--page-wallpaper-image) top / cover no-repeat;
    border-top-right-radius: calc(25vw - #{$grid-gutter-width} * .25);
    background-blend-mode: multiply;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .page-wallpaper-header,
  .page-wallpaper-footer {
    display: none;
  }

  .page-wallpaper-content {
    align-self: center;
  }

  &.page-wallpaper-right::before {
    right: 0;
    left: unset;
    border-top-left-radius: calc(25vw - #{$grid-gutter-width} * .25);
    border-top-right-radius: 0;
  }

  @include media-breakpoint-up(lg) {
    grid-template-rows: rem(60px) auto rem(60px);

    .page-wallpaper-header,
    .page-wallpaper-footer {
      display: block;
    }
  }
}


// Page investors
// ============================================================================

.page-investors {
  padding-top: calc($navbar-investors-min-height + $navbar-primary-min-height);
  color: $gray-700;
  background-color: $investors-body-bg;

  .page-content {
    padding-top: 0;
  }
}
