/*
 * Content:
 *
 * Navbar
 * Navbar brand
 * Navbar toggler
 * Navbar icon link
 * Navbar nav
 * Navbar expand
 * Navbar primary
 * Navbar investors
 */


// Navbar
// ============================================================================

.navbar {
  min-height: $navbar-min-height;
}


// Navbar brand
// ============================================================================

.navbar-brand {
  padding: 0;
}


// Navbar toggler
// ============================================================================

.navbar-toggler {
  border: none;
}


// Navbar icon link
// ============================================================================

.navbar-icon-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $line-height-base * 1em;
  height: $line-height-base * 1em;
  color: $white;
  text-decoration: none;
  background-color: var(--bs-navbar-active-color);
  border-radius: 50%;

  .navbar-icon-link-icon {
    font-size: 1em;
  }

  &:focus-visible {
    outline: 0;
    box-shadow: $nav-link-focus-box-shadow;
  }
}


// Navbar nav
// ============================================================================

.navbar-nav {
  .nav-link {
    &.active,
    &.show {
      font-weight: $font-weight-bold;
    }
  }

  .dropdown-menu {
    position: absolute;
  }
}


// Navbar expand
// ============================================================================

.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-up($next) {
        .navbar-icon-link {
          margin: 0 var(--#{$prefix}navbar-nav-link-padding-x);
        }

        .navbar-nav {
          --#{$prefix}nav-link-padding-y: 0;

          .nav-item:last-child .navbar-icon-link {
            margin-right: 0;
          }
        }
      }
    }
  }
}


// Navbar primary
// ============================================================================

.navbar-primary {
  min-height: $navbar-primary-min-height;
  padding-top: $navbar-primary-padding-y;
  padding-bottom: $navbar-primary-padding-y;
  background-color: $primary;

  .navbar-toggler-icon {
    background-image: url($navbar-primary-toggler-icon-bg);
  }

  .navbar-nav {
    gap: .75rem; // 12px
  }

  .nav-link {
    padding: $navbar-primary-navlink-padding;
    font-weight: $font-weight-normal;
    color: $white;

    &.active {
      color: $white;
      text-decoration: underline;
    }
  }
}


// Navbar investors
// ============================================================================

.navbar-investors {
  min-height: $navbar-investors-min-height;
}
