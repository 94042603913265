/*
 * Content:
 *
 * Button
 * Button table
 * Button expanded
 * Button text
 * Button password
 */


// Button
// ============================================================================

.btn {
  --btn-icon-negative-margin: calc(var(--#{$prefix}btn-padding-x) * -.5);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: spacer(2);

  .icon {
    flex-shrink: 0;
    overflow: hidden;
    font-size: calc(var(--#{$prefix}btn-line-height) * 1em);
    color: transparent;
    background-color: var(--#{$prefix}btn-color);
    // NOTE: DO NOT DELETE, this is necessary for webkit based browsers
    -webkit-background-clip: text; // stylelint-disable-line
    background-clip: text;

    &:first-child {
      margin-left: var(--btn-icon-negative-margin);
    }

    &:last-child {
      margin-right: var(--btn-icon-negative-margin);
    }
  }

  .spinner-border {
    --bs-spinner-width: var(--#{$prefix}btn-font-size);
    --bs-spinner-height: var(--#{$prefix}btn-font-size);
  }

  &:hover {
    box-shadow: var(--#{$prefix}btn-hover-box-shadow);

    .icon {
      background-color: var(--#{$prefix}btn-hover-color);
    }
  }

  .btn-check + &:hover {
    box-shadow: none;

    .icon {
      background-color: var(--#{$prefix}btn-color);
    }
  }

  &:focus-visible {
    .icon {
      background-color: var(--#{$prefix}btn-hover-color);
    }
  }

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    box-shadow: none;

    .icon {
      background-color: var(--#{$prefix}btn-active-color);
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    .icon {
      background-color: var(--#{$prefix}btn-disabled-color);
    }
  }
}


// Button table
// ============================================================================

.btn-table {
  --#{$prefix}btn-border-width: 0;
  --#{$prefix}btn-line-height: 1;

  @include button-size(
    $padding-y: spacer(1),
    $padding-x: spacer(2),
    $font-size: $font-size-base,
    $border-radius: 50%
  );
  @include button-variant(
    $background: transparent,
    $border: transparent,
    $color: $body-color,
    $hover-background: shade-color($primary, $btn-hover-bg-shade-amount),
    $hover-box-shadow: none,
    $active-background: shade-color($primary, $btn-active-bg-shade-amount),
    $disabled-background: transparent,
    $disabled-color: $gray-500
  );
}


// Button expanded
// ============================================================================

[aria-expanded="true"] {
  [data-expanded-true-text]::after,
  &[data-expanded-true-text]::after {
    content: attr(data-expanded-true-text);
  }
}

[aria-expanded="false"] {
  [data-expanded-false-text]::after,
  &[data-expanded-false-text]::after {
    content: attr(data-expanded-false-text);
  }
}


// Button text
// ============================================================================

.btn-text {
  text-decoration: underline;

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }
}

@each $color, $value in $theme-colors {
  .btn-text-#{$color} {
    @include button-variant(
      $background: transparent,
      $border: transparent,
      $color: $value,
      $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
      $active-background: shade-color($value, $btn-active-bg-shade-amount)
    );
  }
}

.btn-text-white {
  @include button-variant(
    $background: transparent,
    $border: transparent,
    $color: $white,
    $hover-background: shade-color($primary, $btn-hover-bg-shade-amount),
    $active-background: shade-color($primary, $btn-active-bg-shade-amount)
  );
}


// Button password
// ============================================================================

.btn-password {
  --#{$prefix}btn-color: #{$input-color};
  --#{$prefix}btn-hover-color: #{$input-color};
  --#{$prefix}btn-active-color: #{$input-color};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-focus-box-shadow: #{$input-focus-box-shadow};
  --btn-icon-negative-margin: 0;

  @include button-size(
    $input-padding-y,
    $input-padding-x,
    $input-font-size,
    $input-border-radius
  );
}
