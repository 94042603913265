/*
 * Content:
 *
 * Nav pills
 */


// Nav pills
// ============================================================================

.nav-pills {
  gap: spacer(2);

  .nav-link {
    padding-left: rem(12px);
    border-left: rem(4px) solid transparent;

    &.active,
    .show > .nav-link {
      font-weight: $font-weight-bold;
      border-color: var(--#{$prefix}nav-pills-link-active-color);
    }
  }
}
