/*
 * Content:
 *
 * Base
 */


// Base
// ============================================================================

[data-bs-toggle="collapse"] {
  .collapse-arrow {
    &::before {
      font-family: "Material Icons Two Tone", sans-serif;
      font-size: 1.5rem;
      font-weight: 400;
      content: "keyboard_arrow_up";
    }
  }

  &.collapsed {
    .collapse-arrow {
      transform: rotate(180deg);
    }
  }
}
