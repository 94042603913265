/*
 * Content:
 *
 * Table
 * Table header sorting
 * Table header shrink
 */


// Table
// ============================================================================

.table {
  > thead {
    vertical-align: top;

    > tr > * {
      color: $table-header-color;
      background-color: $primary;
      border-bottom: none;
    }

    > tr:first-child > :first-child {
      border-top-left-radius: $border-radius-sm;
    }

    > tr:first-child > :last-child {
      border-top-right-radius: $border-radius-sm;
    }
  }

  > tbody {
    font-weight: $font-weight-medium;

    > tr > * > :last-child {
      margin-bottom: 0;
    }
  }
}


// Table header sortable
// ============================================================================

.table > thead > tr > .table-header-sorting {
  position: relative;
  padding-right: add($table-cell-padding-x * 2, $table-header-sorting-icon-size);
  background-image: escape-svg($table-header-sorting-icon);
  background-repeat: no-repeat;
  background-position: right $table-cell-padding-x top $table-cell-padding-y;
  background-clip: padding;
  background-clip: padding-box;
  background-size: $table-header-sorting-icon-size;

  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &.table-header-sorting-asc {
    background-image: escape-svg($table-header-sorting-icon-asc);
  }

  &.table-header-sorting-desc {
    background-image: escape-svg($table-header-sorting-icon-desc);
  }
}


// Table header shrink
// ============================================================================

.table > thead > tr > .table-header-shrink {
  width: .0001%;
}
