/*
 * Content:
 *
 * Modules
 * Alert
 * Alert themes
 * Alert dismissible
 * Highlight alert
 */


// Modules
// ============================================================================

@use "sass:color";
@use "sass:map";


// Alert
// ============================================================================

.alert {
  --#{$prefix}alert-bg: #{$white};
  --#{$prefix}alert-border-color: #{$gray-900};
  --alert-icon: #{escape-svg($alert-icon)};
  padding-left: calc(#{$alert-padding-x} + #{$alert-icon-size} + #{spacer(2)});
  background-image: var(--alert-icon);
  background-repeat: no-repeat;
  background-position: $alert-padding-y $alert-padding-x;
  background-size: $alert-icon-size;
  box-shadow: $box-shadow;
}

// Alert themes
// ============================================================================

@each $state in map-keys($theme-colors) {
  .alert-#{$state} {
    --#{$prefix}alert-color: inherit;
    --#{$prefix}alert-border-color: var(--#{$prefix}#{$state});

    @if $state == "dark" {
      --#{$prefix}alert-bg: #{$white};
    } @else {
      --#{$prefix}alert-bg: var(--#{$prefix}#{$state}-bg-subtle);
    }

    @if map.has-key($alert-icons, $state) {
      --alert-icon: #{escape-svg(map.get($alert-icons, $state))};
    }
  }
}


// Alert dismissible
// ============================================================================

.alert-dismissible {
  .btn-close {
    padding: $alert-padding-y $alert-padding-x;
  }
}
