/*
 * Content:
 *
 * Modules
 * Color system
 * Options
 * Spacing
 * Body
 * Grid containers
 * Grid columns
 * Components
 * Typography
 * Tables
 * Navs
 * Navbar
 * Footer
 * Button + Forms
 * Buttons
 * Forms
 * Badges
 * Alerts
 * Dropdown
 * Cards
 * Progress
 * Close
 * Bootstrap variables
 * Choices variables
 */


// Modules
// ============================================================================

@use "sass:map";


// Color system
// ============================================================================

// Grays
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

// Colors
$blue: #031d6f;
$red: #dc3545;
$yellow: #ffc107;
$yellow-dark: #bd8a00;
$green: #198754;
$cyan: #2d9cdb;
$cyan-dark: #1573a0;

$colors: (
  "blue": $blue,
  "red": $red,
  "yellow": $yellow,
  "green": $green,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800
);

$min-contrast-ratio: 3;

// Themes
$primary: $blue;
$secondary: #444;
$success: $green;
$info: $cyan;
$info-dark: $cyan-dark;
$warning: $yellow;
$warning-dark: $yellow-dark;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "info-dark": $info-dark,
  "warning": $warning,
  "warning-dark": $warning-dark,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);

$primary-bg-subtle: #d9e2fe;
$success-bg-subtle: #f8fefb;
$info-bg-subtle: #f3fdfe;
$warning-bg-subtle: #fffcf2;
$danger-bg-subtle: #fdf4f5;


// Options
// ============================================================================

$enable-rfs: false;
$enable-validation-icons: false;

$prefix: bs-;


// Spacing
// ============================================================================

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25, // 4px
  2: $spacer * .5, // 8px
  3: $spacer, // 16px
  4: $spacer * 1.25, // 20px
  5: $spacer * 1.5, // 24px
  6: $spacer * 2, // 32px
  7: $spacer * 3 // 48px
);


// Body
// ============================================================================

$body-color: $secondary;
$body-bg: $white;
$investors-body-bg: #f9f9f9;

$body-secondary-color: $gray-600;


// Grid containes
// ============================================================================

// The container has a left and right padding equal to $grid-gutter-width/2, by default.
// So its content box width will be max-width - $grid-gutter-width.
$container-max-widths: (
  sm: 548px, // Content box will be 516px (Keep default)
  md: 728px, // Content box will be 696px (Keep default)
  lg: 968px, // Content box will be 936px (Keep default)
  xl: 1164px, // Content box will be 1132px
  xxl: 1328px // Content box will be 1296px (Keep default)
);


// Grid columns
// ============================================================================

$grid-gutter-width: 2rem; // 32px


// Components
// ============================================================================

// Border
$border-color: $gray-400;

// Border radius
$border-radius: .5rem; // 8px

// Box shadow
$box-shadow: 0 .125rem .25rem rgba($black, .25);

// Focus ring
$focus-ring-width: 2px;
$focus-ring-opacity: .15;
$focus-ring-color: rgba(#0d6efd, $focus-ring-opacity);

// Aspect ratios
$aspect-ratios: (
  "letter": calc(279 / 216 * 100%),
);

// Caret
$caret-spacing: spacer(2);


// Typography
// ============================================================================

$font-family-sans-serif: barlow, system-ui, -apple-system, "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-base-in-px: 16px;
$font-size-base: 1rem;
$font-size-sm: rem(14px);

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$line-height-base: 1.5;
$line-height-sm: 1.2;

// Headings
$h1-font-size: rem(56px);
$h2-font-size: rem(48px);
$h3-font-size: rem(40px);
$h4-font-size: rem(32px);
$h5-font-size: rem(24px);
$h6-font-size: rem(20px);

$headings-color: $primary;

// Lead paragraph
$lead-font-size: rem(20px);

// Font sizes map
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  base: $font-size-base,
  sm: $font-size-sm
);


// Tables
// ============================================================================

$table-th-font-weight: $font-weight-bold;

$table-header-color: $white;

$table-header-sorting-icon-size: $line-height-base * 1em;
$table-header-sorting-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$table-header-color}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z'/></svg>");
$table-header-sorting-icon-asc: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$table-header-color}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z'/></svg>");
$table-header-sorting-icon-desc: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$table-header-color}'><path d='M24 24H0V0h24v24z' fill='none' opacity='.87'/><path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/></svg>");


// Navs
// ============================================================================

$nav-link-font-weight: $font-weight-medium;

$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: $primary-bg-subtle;


// Navbar
// ============================================================================

$navbar-min-height: rem(64px);
$navbar-investors-min-height: rem(88px);
$navbar-padding-y: spacer(3);

$navbar-nav-link-padding-x: spacer(2);

$navbar-brand-font-size: $font-size-base;

$navbar-toggler-font-size: $font-size-base;
$navbar-toggler-padding-y: spacer(1);
$navbar-toggler-padding-x: spacer(1);

$navbar-light-color: $body-color;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$navbar-light-disabled-color: $body-secondary-color;
$navbar-light-icon-color: $navbar-light-color;
$navbar-light-toggler-border-color: $navbar-light-icon-color;

$navbar-primary-min-height: rem(48px);
$navbar-primary-padding-y: spacer(1);
$navbar-primary-navlink-padding: spacer(2);
$navbar-primary-navlink-padding-right: spacer(1);
$navbar-primary-toggler-icon-bg: "data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e";


// Footer
// ============================================================================

$footer-min-height: 56px;


// Button + Forms
// ============================================================================

$input-btn-padding-y: spacer(1);
$input-btn-padding-x: spacer(2);


// Buttons
// ============================================================================

$btn-padding-y: spacer(2);
$btn-padding-x: spacer(3);

$btn-padding-y-sm: spacer(1);
$btn-padding-x-sm: spacer(2);
$btn-font-size-sm: $font-size-base;

$btn-border-radius-sm: $border-radius;

$btn-font-weight: $font-weight-bold;

$btn-hover-box-shadow: $box-shadow;

$btn-hover-bg-shade-amount: 45%;
$btn-hover-bg-tint-amount: 45%;
$btn-hover-border-shade-amount: $btn-hover-bg-shade-amount;
$btn-hover-border-tint-amount: $btn-hover-bg-tint-amount;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: $btn-active-bg-shade-amount;
$btn-active-border-tint-amount: $btn-active-bg-tint-amount;


// Forms
// ============================================================================

// Form text
$form-text-margin-top: spacer(2);
$form-text-font-size: $font-size-sm;
$form-text-icon-size: 1rem;

// Label
$form-label-margin-bottom: spacer(2);
$form-label-font-weight: $font-weight-medium;

// Input
$input-font-weight: $font-weight-medium;
$input-color: $gray-900;
$input-border-color: $gray-600;

$input-placeholder-color: $gray-500;

$input-disabled-color: $gray-700;
$input-disabled-bg: $gray-200;
$input-disabled-border-color: $input-disabled-bg;

$input-focus-border-color: $primary;

// Check
$form-check-input-width: rem(24px);
$form-check-padding-start: $form-check-input-width + spacer(1);
$form-check-margin-bottom: 0;

$form-check-input-bg: transparent;
$form-check-input-border: 0;

$form-check-input-color: $input-color;
$form-check-input-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$form-check-input-color}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z'/></svg>");
$form-check-radio-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$form-check-input-color}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>");

$form-check-input-checked-color: $body-color;
$form-check-input-checked-bg-color: transparent;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$form-check-input-checked-color}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M5 19h14V5H5v14zm2.41-7.4l2.58 2.58 6.59-6.59L17.99 9l-8 8L6 13.01l1.41-1.41z' opacity='.3'/><path d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$form-check-input-checked-color}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/><circle cx='12' cy='12' r='5'/></svg>");

$form-check-input-indeterminate-color: $form-check-input-checked-color;
$form-check-input-indeterminate-bg-color: transparent;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$form-check-input-indeterminate-color}'><path d='M0 0h24v24H0z' fill='none'/><path d='M5 19h14V5H5v14zm2-8h10v2H7v-2z' opacity='.3'/><path d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 11h10v2H7z'/></svg>");

$form-check-input-disabled-opacity: 1;
$form-check-label-disabled-opacity: 1;
$form-check-btn-check-disabled-opacity: 1;
$form-check-input-disabled-color: $gray-500;

// Switch
$form-switch-color: $input-border-color;
$form-switch-width: rem(46px);
$form-switch-padding-start: $form-switch-width + spacer(3);
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");

// Select
$form-select-padding-x: $input-btn-padding-x;
$form-select-bg-size: rem(24px);

$form-select-disabled-color: $input-disabled-color; // This value can't be `null` for overriding choices styles

$form-select-indicator-padding: spacer(1) + $form-select-bg-size + $form-select-padding-x;
$form-select-indicator-color: $input-color;
$form-select-indicator: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><g clip-path='url(#clip0_603_2740)'><path d='M7 10L12 15L17 10H7Z' fill='#{$form-select-indicator-color}'/></g><defs><clipPath id='clip0_603_2740'><rect width='24' height='24'/></clipPath></defs></svg>");

$form-select-multiple-button-color: $white;
$form-select-multiple-button-icon: url("data:image/svg+xml,<svg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'><g clip-path='url(#clip0_603_2389)'><path opacity='0.3' d='M6 2C3.795 2 2 3.795 2 6C2 8.205 3.795 10 6 10C8.205 10 10 8.205 10 6C10 3.795 8.205 2 6 2ZM8.5 7.795L7.795 8.5L6 6.705L4.205 8.5L3.5 7.795L5.295 6L3.5 4.205L4.205 3.5L6 5.295L7.795 3.5L8.5 4.205L6.705 6L8.5 7.795Z' fill='#{$form-select-multiple-button-color}'/><path d='M6 1C3.235 1 1 3.235 1 6C1 8.765 3.235 11 6 11C8.765 11 11 8.765 11 6C11 3.235 8.765 1 6 1ZM6 10C3.795 10 2 8.205 2 6C2 3.795 3.795 2 6 2C8.205 2 10 3.795 10 6C10 8.205 8.205 10 6 10ZM7.795 3.5L6 5.295L4.205 3.5L3.5 4.205L5.295 6L3.5 7.795L4.205 8.5L6 6.705L7.795 8.5L8.5 7.795L6.705 6L8.5 4.205L7.795 3.5Z' fill='#{$form-select-multiple-button-color}'/></g><defs><clipPath id='clip0_603_2389'><rect width='12' height='12' fill='white'/></clipPath></defs></svg>");


// Badges
// ============================================================================

$badge-font-weight: $font-weight-bold;
$badge-padding-y: spacer(1);
$badge-padding-x: spacer(2);


// Alerts
// ============================================================================

$alert-border-width: 2px;
$alert-icon-size: $line-height-base * 1em;
$alert-dismissible-padding-r: rem(56px);
$alert-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$body-color}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 13h-2v-6h2v6zm0-8h-2V7h2v2z' opacity='.3'/><path d='M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z'/></svg>");

$alert-icons: (
  "success": url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$success}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm-2 13-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z' opacity='.3'/><path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z'/></svg>"),
  "info": url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$info}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 13h-2v-6h2v6zm0-8h-2V7h2v2z' opacity='.3'/><path d='M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z'/></svg>"),
  "warning": url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$warning}'><path d='M4.47 19h15.06L12 5.99 4.47 19zM13 18h-2v-2h2v2zm0-4h-2v-4h2v4z' opacity='.3'/><path d='M1 21h22L12 2 1 21zm3.47-2L12 5.99 19.53 19H4.47zM11 16h2v2h-2zm0-6h2v4h-2z'/></svg>"),
  "danger": url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$danger}'><path d='M12 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm1 13h-2v-2h2v2zm0-4h-2V7h2v6z' opacity='.3'/><path d='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-1-5h2v2h-2zm0-8h2v6h-2z'/></svg>")
);


// Dropdown
// ============================================================================

$dropdown-spacer: spacer(1);
$dropdown-border-color: $gray-400;
$dropdown-item-padding-y: spacer(2);
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: shade-color($primary, $btn-hover-bg-shade-amount);
$dropdown-link-color: $gray-900;


// Cards
// ============================================================================

$card-spacer-y: spacer(5);
$card-spacer-x: spacer(5);
$card-border-width: 0;
$card-box-shadow: 0 rem(1px) rem(4px) rgba($black, .25);


// Progress
// ============================================================================

$progress-bg: #f5f5f5;


// Close
// ============================================================================

$btn-close-width: $line-height-base * 1em;
$btn-close-color: $black;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$btn-close-color}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'/></svg>");
$btn-close-opacity: 1;
$btn-close-hover-opacity: 1;


// Breadcrumb
// ============================================================================

$breadcrumb-padding-y: spacer(5);
$breadcrumb-active-color: $gray-700;
$breadcrumb-divider: "keyboard_arrow_right";
$breadcrumb-divider-color: $primary;

// Tooltip
// ============================================================================

$tooltip-color: $white;
$tooltip-bg: $primary;
$tooltip-opacity: 1;
$tooltip-padding-y: spacer(2);
$tooltip-padding-x: spacer(2);
$tooltip-border-radius: 4px;

// Bootstrap variables
// ============================================================================

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";


// Choices variables
// ============================================================================

$choices-selector: "choices";
