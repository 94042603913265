/*
 * Content:
 *
 * Fixed notification
 */


// Fixed notification
// ============================================================================

.fixed-notification {
  position: fixed;
  top: spacer(6);
  right: $container-padding-x * .5;
  z-index: $zindex-fixed;
  width: calc(100% - #{$container-padding-x});
  max-width: rem(356px);

  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint) {
      right: calc((100vw - #{$container-max-width} + #{$container-padding-x}) * .5);
    }
  }
}
