/*
 * Content:
 *
 * Description list inline
 * Description list definition emphasis
 */


// Description list inline
// ============================================================================

.dl-inline {
  dt,
  dd {
    display: inline;
  }

  dt::after {
    content: ":";
  }

  dd {
    margin-bottom: 0;
    font-weight: $font-weight-medium;

    &:empty::after {
      content: "-";
    }

    :last-child {
      margin-bottom: 0;
    }
  }
}


// Description list definition emphasis
// ============================================================================

.dl-d-emphasis {
  dt {
    font-weight: 400;
  }

  dd {
    font-weight: 700;
  }
}
