/*
 * Content:
 *
 * Modules
 * Bootstrap mixins
 */


// Modules
// ============================================================================

@use "sass:color";
@use "sass:map";


// Bootstrap mixins
// ============================================================================

@import "~bootstrap/scss/mixins";

@mixin button-variant(
  $background,
  $border,
  $color: color-contrast($background),
  $hover-background: shade-color($background, $btn-hover-bg-shade-amount),
  $hover-border: shade-color($border, $btn-hover-border-shade-amount),
  $hover-color: color-contrast($hover-background),
  $hover-box-shadow: $btn-hover-box-shadow,
  $active-background: shade-color($background, $btn-active-bg-shade-amount),
  $active-border: shade-color($border, $btn-active-border-shade-amount),
  $active-color: color-contrast($active-background),
  $disabled-background: $gray-200,
  $disabled-border: $disabled-background,
  $disabled-color: $white
) {
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-bg: #{$background};
  --#{$prefix}btn-border-color: #{$border};
  --#{$prefix}btn-hover-color: #{$hover-color};
  --#{$prefix}btn-hover-bg: #{$hover-background};
  --#{$prefix}btn-hover-border-color: #{$hover-border};
  --#{$prefix}btn-hover-box-shadow: #{$hover-box-shadow};
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb(color.mix($color, $border, 15%))};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$active-border};
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$disabled-color};
  --#{$prefix}btn-disabled-bg: #{$disabled-background};
  --#{$prefix}btn-disabled-border-color: #{$disabled-border};
  --#{$prefix}btn-disabled-opacity: 1;
}

@mixin button-outline-variant(
  $color,
  $hover-background: shade-color($color, $btn-hover-bg-shade-amount),
  $hover-border: $hover-background,
  $hover-color: color-contrast($hover-background),
  $hover-box-shadow: $btn-hover-box-shadow,
  $active-background: shade-color($color, $btn-active-bg-shade-amount),
  $active-border: $active-background,
  $active-color: color-contrast($active-background)
) {
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-border-color: #{$color};
  --#{$prefix}btn-hover-color: #{$hover-color};
  --#{$prefix}btn-hover-bg: #{$hover-background};
  --#{$prefix}btn-hover-border-color: #{$hover-border};
  --#{$prefix}btn-hover-box-shadow: #{$hover-box-shadow};
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($color)};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$active-border};
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$white};
  --#{$prefix}btn-disabled-bg: #{$gray-200};
  --#{$prefix}btn-disabled-border-color: #{$gray-200};
  --#{$prefix}btn-disabled-opacity: 1;
  --#{$prefix}gradient: none;
}
