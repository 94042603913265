/*
 * Content:
 *
 * Form text
 * Form control
 * Form check
 * Form switch
 * Form password
 */


// Form text
// ============================================================================

.form-text {
  display: block;
  line-height: 1rem;

  ul {
    @include list-unstyled();
    margin-bottom: 0;

    li {
      padding-left: $form-text-icon-size + spacer(1);
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$body-color}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm-2 13-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z' opacity='.3'/><path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z'/></svg>"));
      background-repeat: no-repeat;
      background-size: $form-text-icon-size;
    }

    li + li {
      margin-top: spacer(1);
    }
  }
}


// Form control
// ============================================================================

.form-control {
  &::placeholder {
    font-weight: $input-font-weight;
  }

  &:disabled::placeholder {
    color: $input-disabled-color;
  }

  .input-group &::file-selector-button {
    display: none;
  }
}


// Form check
// ============================================================================

.form-check {
  padding-top: add($input-padding-y, $input-border-width);
  padding-bottom: add($input-padding-y, $input-border-width);
}

.form-check-input {
  &[type="checkbox"] {
    --#{$prefix}form-check-bg-image: #{escape-svg($form-check-input-bg-image)};
  }

  &[type="radio"] {
    --#{$prefix}form-check-bg-image: #{escape-svg($form-check-radio-bg-image)};
  }

  &:disabled {
    &[type="checkbox"] {
      --#{$prefix}form-check-bg-image: #{escape-svg(str-replace($form-check-input-bg-image, #{$form-check-input-color}, #{$form-check-input-disabled-color}))};
    }

    &[type="radio"] {
      --#{$prefix}form-check-bg-image: #{escape-svg(str-replace($form-check-radio-bg-image, #{$form-check-input-color}, #{$form-check-input-disabled-color}))};
    }

    &[type="checkbox"]:checked {
      --#{$prefix}form-check-bg-image: #{escape-svg(str-replace($form-check-input-checked-bg-image, #{$form-check-input-checked-color}, #{$form-check-input-disabled-color}))};
    }

    &[type="radio"]:checked {
      --#{$prefix}form-check-bg-image: #{escape-svg(str-replace($form-check-radio-checked-bg-image, #{$form-check-input-checked-color}, #{$form-check-input-disabled-color}))};
    }

    &[type="checkbox"]:indeterminate {
      --#{$prefix}form-check-bg-image: #{escape-svg(str-replace($form-check-input-indeterminate-bg-image, #{$form-check-input-indeterminate-color}, #{$form-check-input-disabled-color}))};
    }
  }

  // Use disabled attribute in addition of :disabled pseudo-class
  // See: https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .form-check-label {
      color: $gray-700;
    }
  }
}

.form-check-label {
  font-weight: $input-font-weight;
}


// Form switch
// ============================================================================

.form-switch {
  .form-check-input {
    background-color: $input-bg;
    border: $border-width solid $input-border-color;

    &:checked {
      background-color: $primary;
      border-color: $primary;
    }

    &:disabled {
      --#{$prefix}form-switch-bg: #{escape-svg(str-replace($form-switch-bg-image, #{$form-switch-color}, #{$input-disabled-color}))};
      background-color: $input-disabled-bg;
      border-color: $input-disabled-border-color;
    }
  }
}


// Form password
// ============================================================================

.form-password {
  position: relative;

  .form-control {
    padding-right: $input-padding-x * 2 + ($btn-font-size * $input-btn-line-height);
  }

  .btn-password {
    position: absolute;
    top: 0;
    right: 0;
  }
}
