/*
 * Content:
 *
 * Card
 * Card collapse
 */


// Card
// ============================================================================

.card {
  box-shadow: $card-box-shadow;
}


// Card collapse
// ============================================================================

.card-collapse {
  margin-right: -$card-spacer-x;
  margin-left: -$card-spacer-x;
}

.card-collapse-body {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: $card-spacer-y;
  box-shadow: inset 0 0 rem(8px) rgba($black, .25);
}
