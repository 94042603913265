/*
 * Content:
 *
 * Root
 */


// Root
// ============================================================================

:root {
  // Colors
  @each $color, $value in $grays-rgb {
    --#{$prefix}gray-#{$color}-rgb: #{$value}; // stylelint-disable-line custom-property-pattern
  }
}
