/*
 * Content:
 *
 * Breadcrumb item
 */


// Breadcrumb item
// ============================================================================

.breadcrumb-item {
  & + .breadcrumb-item {
    &::before {
      font-family: "Material Icons Two Tone", sans-serif;
    }
  }
}
