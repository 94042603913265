/*
 * Content:
 *
 * Dropdown toggle
 */


// Dropdown toggle
// ============================================================================

.dropdown-toggle {
  &::after {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: rem(24px);
    height: rem(24px);
    font-family: "Material Icons Two Tone", sans-serif;
    vertical-align: middle;
    content: "arrow_drop_down";
    border: none;
  }

  &.show {
    &::after {
      transform: rotate(180deg);
    }
  }
}
